import React from 'react';
import { useSelector } from 'react-redux';
import type { Components } from 'react-virtuoso';
import { useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';
import { selectFeedStatuses } from 'store/selectors';
import { ErrorState } from 'wui/ErrorState';
import { FeedItemSkeleton } from 'common/components/FeedItem';

import type { IVirtuosoContext } from '../types';

import classes from '../FeedItemList.scss';

export const Footer: Components<IFeedItem, IVirtuosoContext>['Footer'] = (
  props,
) => {
  const { t } = useTranslation();

  const { fetchMore } = useSelector(selectFeedStatuses);

  const { onRetry } = props.context!;

  if (fetchMore.pending) {
    return (
      <div className={classes.footer}>
        {new Array(10).fill(0).map((_, index) => (
          <FeedItemSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (fetchMore.error) {
    return (
      <ErrorState
        wired
        variant="section"
        title={t('groups-web.discussion.error-state.title')}
        subtitle={t('groups-web.discussion.error-500.title')}
        actionLabel={t('groups-web.discussion.error-state.cta')}
        onRetry={onRetry}
      />
    );
  }

  return null;
};

Footer.displayName = 'Footer';
