import React from 'react';
import type { Components } from 'react-virtuoso';
import { ErrorMonitorBoundary, useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';
import { ErrorState } from 'wui/ErrorState';
import { Fade } from 'wui/Fade';

import type { IVirtuosoContext } from '../types';

export const Item: Components<IFeedItem, IVirtuosoContext>['Item'] =
  // eslint-disable-next-line react/display-name
  React.forwardRef((props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const context = props.context!;

    const { t } = useTranslation();

    return (
      <Fade {...props}>
        <div ref={ref}>
          <ErrorMonitorBoundary
            fallback={
              <ErrorState
                wired
                title={t('groups-web.error-state.title')}
                subtitle={t('groups-web.error-state.subtitle')}
                actionLabel={t('groups-web.error-state.retry.label')}
                onRetry={context.onRetry}
              />
            }
          >
            {props.children}
          </ErrorMonitorBoundary>
        </div>
      </Fade>
    );
  });
